<template>
  <el-container>
    <el-main>
       <el-row>
        <el-col>
          <router-view/>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
// import {authenticateJWT} from "@/libs/common.js";

export default {
  name: "Personal",
  data() {
    return {
    }
  },
  methods: {
    
  },
  // created() {
  //   if (window.history.state.back == null && !authenticateJWT(this.$store.state.personal.jwt)) {
  //     console.log("clear storage")
  //     this.$store.commit("resetPersonalState")
  //     // window.localStorage.removeItem("personal")
  //   }
  // }
}
</script>